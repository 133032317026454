@import url(https://fonts.googleapis.com/css?family=Michroma);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
  background-color: #282d31;/* #31343c;*/
  color:#FFF;
  font-size: 16px;
}
.App {
}
.App-nav {
  background-color: #212529; 
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
h2 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
h3 {
  margin: 0; 
}
h4 {
  margin:0;
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: #212529 solid 3px;
}
h4 span {
  padding-bottom: 5px;
  border-bottom: #4caf50 solid 3px;
}
.App-body {
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 14px;
  overflow: hidden;
}
.homeIcon {
  float: left;
  font-size: 36px;
  margin-top: 8px;
}
.iconFormat {
  margin-top: -6px;
  float: left;
  margin-right: 10px
}
.subHeaderText {
  font-size: 14px;
  color: #585c64;
}
.homeHeaderInfo {
  float: right;
  font-size: 14px;
  color: #585c64;
  margin-top: 14px;
  text-align: right;
}
.listHeader {
  font-weight: bold;
}
.listContent {
  color: #90969e;
}
.listContent span {
  float:right;
}
.App-link {
  color: #61dafb;
}
.navbar-logo {
	font-family: 'Michroma', sans-serif;
  font-weight:bold;
  color: #FFF;
  text-decoration: none;
	padding:11px 15px;
  text-transform:uppercase;
  font-size: 20px;
}

.navbar-logo span,
.flip {
	-webkit-transform:rotateY(180deg);
  -moz-transform:rotateY(180deg);
  -o-transform:rotateY(180deg);
  -ms-transform:rotateY(180deg);
  unicode-bidi:bidi-override;
  direction:rtl;
  float:right;
  color:#4caf50;
}
.message-id {
  background: #4caf50;
  color: #FFF;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#radar-container {
  position: relative;
  text-align: center;
  align-content: center;
  left: 50%;
  margin: 90px 0 205px 0;
}

.radar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  float: left;
  background: #4caf50;
  transition: all 0.2s;
  animation: r5 1.5s 0s ease-out infinite;
}
@keyframes r5 {
  0% {
    box-shadow: 0 0 8px 6px rgb(76, 175, 80), 0 0 0px 0px transparent, 0 0 0px 0px rgb(76, 175, 80);
  }
  10% {
    box-shadow: 0 0 8px 6px rgb(76, 175, 80), 0 0 12px 10px transparent, 0 0 12px 14px rgb(76, 175, 80);
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(26, 140, 255, 0), 0 0 0px 60px transparent, 0 0 0px 60px rgba(26, 140, 255, 0);
  }
}
